<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="1000"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="公司名称"
          prop="companyName"
          v-bind="formItemLayout"
      >
        <span style="color:#999;margin-left:5px;">{{ form.companyName }}  </span>
        <a :href="backReport.reportUrl"
           v-if="backReport&&backReport.reportUrl"
           target="_blank">查看已有背调报表</a>
      </a-form-model-item>

      <a-form-model-item label="申请人" v-bind="formItemLayout" prop="url">
        <span style="color:#999;margin-left:5px;">{{ form.userInfo && form.userInfo.username }}</span>
      </a-form-model-item>
      <a-form-model-item label="订单类型" v-bind="formItemLayout" prop="applyMode">
        <a-radio-group v-model="form.applyMode">
          <a-radio :value="1">VIP免费</a-radio>
          <a-radio :value="2">VIP优惠</a-radio>
          <a-radio :value="3">普通订单</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="订单状态" v-bind="formItemLayout" prop="status">
        <a-radio-group v-model="form.status">
          <a-radio :value="0">新建</a-radio>
          <a-radio :value="1">待支付</a-radio>
          <a-radio :value="2">背调中</a-radio>
          <a-radio :value="3">已完成</a-radio>
          <a-radio :value="-1">已拒绝</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="支付状态" v-bind="formItemLayout" prop="payStatus">
        <a-radio-group v-model="form.payStatus">
          <a-radio :value="0">待支付</a-radio>
          <a-radio :value="1">已支付</a-radio>
          <a-radio :value="2">VIP免费</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="发起时间" v-bind="formItemLayout" prop="createTime">
        <span style="color:#999;margin-left:5px;">{{ form.createTime }}</span>
      </a-form-model-item>
      <a-form-model-item v-if="form.finishTime" label="完成时间" v-bind="formItemLayout" prop="finishTime">
        <span style="color:#999;margin-left:5px;">{{ form.finishTime }}</span>
      </a-form-model-item>

      <a-form-model-item ref="doorHeader" label="上传背调报告" v-bind="formItemLayout">
        <div class="clearfix">
          <a-upload
              name="file"
              list-type="text"
              class="avatar-uploader"
              accept="application/pdf"
              withCredentials
              :multiple="false"
              :data="{ bucketType: 'public', uploadType: 'image',prefix:'backtrack' }"
              :headers="headers"
              :file-list="imageList"
              action="admin/upload/uploadImage"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="(info) => handleImageChange(info)"
          >
            <div v-if="imageList.length < 1" style="display: flex;text-align: center;justify-content: center;flex-direction: column;padding:10px;border:1px solid #CCC;">
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </div>
      </a-form-model-item>
      <a-form-model-item label="描述" v-bind="formItemLayout" prop="contentDesc">
        <a-textarea v-model="form.remark" :max-length="255"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import moment from 'moment'

export default {
  name: "modifyModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 15},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      form: {},
      imageList: [],
      rules: {},
      backReport: undefined
    };
  },
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    }
  },
  created() {
  },
  methods: {
    handleImageChange(info) {
      this.imageList = info.fileList || [];
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === "application/pdf";
      if (!isJpgOrPng) {
        this.$message.error("只能支持PDF格式文档!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 30;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过30MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    async handlePreview(file) {
      window.open(file.url||file.response.data, "_blank")
    },
    async showModal(record, mode) {
      this.visible = true;
      this.confirmLoading = false;
      this.mode = mode || 'new'

      const that = this;
      if (this.mode === 'update') {
        this.title = "修改背调群ID：" + record.id;
        this.form = {
          ...record,
        };
        let imageList = [record.reportUrl];
        let array = [];
        imageList.forEach((v) => {
          if (!v) return;
          let path = that.$helper.getFullImgPath(v);
          array.push({
            type: "application/pdf",
            name: v,
            uid: v,
            status: "done",
            thumbUrl: path,
            response: {data: path, code: 0},
          });
        });
        this.imageList = array;

        this.$get('backtrack/report/checkExists', {companyId: record.companyId}).then(res => {
          this.backReport = res.data;
        })
      } else {
        //not support
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.form.status == 3) {
            if (this.imageList.length == 0) {
              if (!this.backReport) {
                this.$message.warn("请上传背调报表")
                return
              } else {
                this.form.reportUrl = this.backReport.reportUrl
              }
            } else {
              this.form.reportUrl = this.imageList.map((item) => {
                return item.response.data;
              })[0];
            }
          }

          this.$postJson("backtrack/apply/update", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("修改成功");
            this.$emit("success");
          }).catch(() => {
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
